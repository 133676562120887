import * as moment from 'moment-timezone';

export const numberWithCommas = (x) => {
  if (x) {
    const xFormat = parseFloat(x);
    const parts = xFormat.toFixed(2).split('.');
    const num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '');
    return num;
  }
  return '0';
};

export const wholeNumberWithCommas = (x) => {
  if (x) {
    const num = Math.round(x)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return num;
  }
  return '0';
};

export const getRemainingDays = (endDate) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const end = moment(endDate, 'DD-MM-YYYY HH:mm');
  const today = new Date();
  let pst = today.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles'
  });
  pst = moment(pst, 'DD-MM-YYYY HH:mm');
  const diffDays = Math.round(Math.abs((end - pst) / oneDay));
  return diffDays;
};

export const capitalizeString = (string) => {
  return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};
