import React from 'react';
import { connect } from 'react-redux';
import PreLaunch from './PreLaunch';
import RoadShow from './RoadShow';
import Auction from './Auction';
import Settlement from './Settlement';
import Close from './Close';

function BondOffering(props) {
  function getCurrentLabel() {
    const { status } = props;
    switch (status) {
      case -1:
        return 'Pre Launch';
      case 0:
        return 'Roadshow Started';
      case 1:
        return 'Pre Order Started';
      case 2:
        return 'Offering Started';
      case 3:
        return 'Ceased Orders Cancellation';
      case 4:
        return 'Offering Pre Completed';
      case 5:
        return 'Offering Completed';
      case 6:
        return 'Reconfirmation';
      case 7:
        return 'Fully Allocated';
      case 8:
        return 'Offering Closed';
      case 9:
        return 'Settlement';
      case 10:
        return 'Restricted Trading';
      case 11:
        return 'Offering Cancelled';
      case 12:
        return 'Offering Failed';
      case 13:
        return ''; // processing_settlement
      case 14:
        return 'MATURED';
      default:
        return '';
    }
  }

  const { status } = props;
  return (
    <div>
      {status === -1 && <PreLaunch />}
      {status === 0 && <RoadShow />}
      {(status === 1 || status === 2 || status === 3) && (
        <Auction status={status} status_label={getCurrentLabel()} />
      )}
      {(status === 4 || status === 5 || status === 6 || status === 7 || status === 8) && (
        <Close status_label={getCurrentLabel()} />
      )}
      {(status === 9 || status === 10 || status === 11 || status === 12) && (
        <Settlement status_label={getCurrentLabel()} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { offering } = state;
  return {
    raise: offering.raise,
    yield: offering.yield,
    status: offering.status,
    percent_subscribed: offering.percent_subscribed,
    revenue: offering.revenue,
    time_remaining: offering.time_remaining
  };
};

export default connect(mapStateToProps)(BondOffering);
