import React from 'react';
import { connect } from 'react-redux';
import { numberWithCommas } from '../../util/functions';

function PreLaunch(props) {
  const { revenue, yield: bondYield, payments } = props;
  return (
    <div>
      <div className="stats">
        <div
          style={{
            padding: '15px 25px',
            backgroundColor: '#dedede',
            color: '#fff',
            fontSize: 15
          }}>
          Pre Launch
        </div>
        <div className="card-data-box">
          <div className="row">
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Raising</div>
                <h2 className="card-title">${numberWithCommas(revenue)}</h2>
              </div>
            </div>
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Interest Rate</div>
                <h2 className="card-title">{bondYield}%</h2>
              </div>
            </div>
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Term</div>
                <h2 className="card-title">{payments} months</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { offering } = state;
  return {
    raise: offering.raise,
    revenue: offering.maximumAmountBeingRaised,
    yield: offering.yield,
    payments: offering.payments
  };
};

export default connect(mapStateToProps)(PreLaunch);
