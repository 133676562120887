import React, { useCallback } from 'react';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import Config from '../util/Config';
import colors from '../util/colors';
import fallbackImg from '../assets/img/f8e71c.png';

function Reply({ item, parentId, onEditReply, onDeleteReply }) {
  const handleEditReply = useCallback(() => {
    onEditReply(item, parentId);
  }, [item, parentId, onEditReply]);

  const handleDeleteReply = useCallback(() => {
    onDeleteReply(item.comment_id, parentId);
  }, [item.comment_id, parentId, onDeleteReply]);

  const handleEditKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') handleEditReply();
    },
    [handleEditReply]
  );

  const handleDeleteKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') handleDeleteReply();
    },
    [handleDeleteReply]
  );

  return (
    <div key={`reply_${item.comment_id}`} style={styles.reply}>
      <div style={styles.reply_content}>
        <img
          style={styles.reply_image}
          className="userIcon"
          src={item.profile_image}
          width="30"
          height="30"
          alt="User Icon"
          onError={(e) => {
            e.target.src = fallbackImg;
          }}
        />
        <div style={{ marginLeft: 10 }}>
          <div style={styles.reply_title}>{item.username}</div>
          <p>{parse(item.comment)}</p>
          <p>{moment(item.commented_at, Config.DATE_FORMAT).format(Config.OUTPUT_DATE_FORMAT)}</p>
          {item.isOwner && (
            <div style={styles.toolbar}>
              <div
                onClick={handleEditReply}
                onKeyDown={handleEditKeyDown}
                role="button"
                tabIndex={0}
                style={styles.action}
                className="action-info">
                <i className="material-icons">edit</i>
                <label>Edit</label>
              </div>
              <div
                onClick={handleDeleteReply}
                onKeyDown={handleDeleteKeyDown}
                role="button"
                tabIndex={0}
                style={{ ...styles.action, marginLeft: 10 }}
                className="action-delete">
                <i className="material-icons">delete</i>
                <label>Delete</label>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const styles = {
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  reply: {
    padding: 50,
    borderBottom: `1px solid ${colors.LIGHT_GREY_1}`,
    backgroundColor: colors.BACKGROUND_GRAY
  },
  reply_content: {
    display: 'flex'
  },
  reply_image: {
    borderRadius: 15
  },
  reply_title: {
    fontFamily: 'Lato',
    fontWeight: 500,
    marginBottom: 20
  }
};

export default React.memo(Reply);
