/* eslint-disable react/no-unused-state */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR
} from 'react-redux-notify';
import { showLoading, hideLoading } from '../reducers/ux/actions';
import Api from '../util/Api';

// styles
import useStyles from '../web-component-library/smbx-commons/styles/UI/buttonStyle';

function Settings(props) {
  const [changePassword, setChangePassword] = useState({
    password: '',
    newPassword: '',
    confirmPassword: ''
  });

  const classes = useStyles();

  const isFormComplete = () => {
    return Object.values(changePassword).every((val) => val.length > 0);
  };

  const onChangeInput = (event) => {
    setChangePassword({ ...changePassword, [event.target.name]: event.target.value });
  };

  const saveChanges = async (event) => {
    event.preventDefault();
    const { password, newPassword, confirmPassword } = changePassword;
    if (newPassword === confirmPassword) {
      props.dispatch(showLoading());
      const response = await Api.getInstance().updatePassword(password, confirmPassword);
      props.dispatch(hideLoading());
      if (response.success) {
        props.dispatch(
          createNotification({
            type: NOTIFICATION_TYPE_SUCCESS,
            message: 'The password has been updated',
            duration: 1000
          })
        );
      } else {
        props.dispatch(
          createNotification({
            type: NOTIFICATION_TYPE_ERROR,
            message: response.message,
            duration: 1000
          })
        );
      }
    } else {
      props.dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Passwords don't match",
          duration: 2000
        })
      );
    }
  };

  const { password, newPassword, confirmPassword } = changePassword;
  return (
    <div>
      <div className="smbx-content team">
        <h5>Change Password</h5>
        <form onSubmit={saveChanges}>
          <div className="row">
            <div className="col">
              <div className="row align-items-center">
                <div className="col-4 text-center">
                  <label>Enter Current Password</label>
                </div>
                <div className="col">
                  <input
                    value={password}
                    onChange={onChangeInput}
                    name="password"
                    type="password"
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-4 text-center">
                  <label>Enter New Password</label>
                </div>
                <div className="col">
                  <input
                    value={newPassword}
                    onChange={onChangeInput}
                    name="newPassword"
                    type="password"
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-4 text-center">
                  <label>Confirm New Password</label>
                </div>
                <div className="col-8">
                  <input
                    value={confirmPassword}
                    onChange={onChangeInput}
                    name="confirmPassword"
                    type="password"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Button
                className={`${classes.root} ${classes.primary} ${classes.ft16}`}
                type="submit"
                style={{ maxWidth: '400px' }}
                disabled={!isFormComplete()}>
                Save Changes
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile_picture: state.user.avatar
  };
}

export default connect(mapStateToProps)(Settings);
