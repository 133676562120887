import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom'; // Import withRouter
import {
  fullfillComments,
  postComment,
  deleteComment,
  updateComment,
  deleteReply,
  updateReply
} from '../reducers/comments/actions';
import { showConfirm } from '../reducers/ux/actions';
import ClosablePanel from '../components/ClosablePanel';
import Comment from '../components/Comment';
import 'react-quill/dist/quill.snow.css';
import ReplyForm from '../components/ReplyForm';

function Comments({ location }) {
  // Destructure location from props
  const comments = useSelector((state) => state.comments);
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const [isEditing, setIsEditing] = useState({ value: false, main: false });
  const [editInfo, setEditInfo] = useState('');
  const [editValue, setEditValue] = useState('');

  // Use location.key if available; otherwise fallback to location.pathname
  useEffect(() => {
    dispatch(fullfillComments());
  }, [dispatch, location.key || location.pathname]);

  const handleEditComment = useCallback((comment) => {
    setEditValue(comment.comment);
    setEditInfo(comment.comment_id);
    setIsEditing({ value: true, main: true });
    setCollapsed(false);
    window.scrollTo(0, 0);
  }, []);

  const handleEditReply = useCallback((comment, parentId) => {
    setEditValue(comment.comment);
    setEditInfo({ id: comment.comment_id, parentId });
    setIsEditing({ value: true, main: false });
    setCollapsed(false);
    window.scrollTo(0, 0);
  }, []);

  const handleDeleteComment = useCallback(
    (commentId) => {
      dispatch(
        showConfirm(
          'Delete Comment',
          'Are you sure you want to delete the comment? You won’t be able to revert this.',
          () => {
            dispatch(deleteComment(commentId));
          }
        )
      );
    },
    [dispatch]
  );

  const handleDeleteReply = useCallback(
    (replyId, commentId) => {
      dispatch(
        showConfirm(
          'Delete Reply',
          'Are you sure you want to delete the reply? You won’t be able to revert this.',
          () => {
            dispatch(deleteReply(replyId, commentId));
          }
        )
      );
    },
    [dispatch]
  );

  const handlePostComment = useCallback(
    (newComment) => {
      dispatch(postComment(newComment));
      setCollapsed(true);
    },
    [dispatch]
  );

  const handleUpdateComment = useCallback(
    (value) => {
      dispatch(updateComment(editInfo, value));
      setCollapsed(true);
      setEditValue('');
    },
    [dispatch, editInfo]
  );

  const handleUpdateReply = useCallback(
    (value) => {
      dispatch(updateReply(editInfo.parentId, editInfo.id, value));
      setCollapsed(true);
      setEditValue('');
    },
    [dispatch, editInfo]
  );

  const toggleCollapsed = useCallback(() => {
    setCollapsed((prev) => !prev);
    setIsEditing({ value: false, main: false });
  }, []);

  const handleToggleCollapsedKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') toggleCollapsed();
    },
    [toggleCollapsed]
  );

  // Determine the appropriate submit handler
  // Compute submit handler without a nested ternary
  let submitHandler = handlePostComment;
  if (isEditing.value) {
    submitHandler = isEditing.main ? handleUpdateComment : handleUpdateReply;
  }

  return (
    <div>
      <ClosablePanel title="Investors can post comments and questions about the bond offering.">
        <p>
          Use this page to reply to investor questions and share updates about your business or the
          bond offering.
        </p>
      </ClosablePanel>
      <div className="smbx-content createPost">
        <h6 className="collapsible h5">
          Post an update{' '}
          <button
            type="button"
            onClick={toggleCollapsed}
            onKeyDown={handleToggleCollapsedKeyDown}
            style={{
              float: 'right',
              cursor: 'pointer',
              userSelect: 'none',
              background: 'none',
              border: 'none'
            }}>
            {collapsed ? 'add' : 'close'}
          </button>
        </h6>
        {!collapsed && (
          <div className="comment">
            <ReplyForm
              editValue={editValue}
              onSubmitForm={submitHandler}
              buttonName="Post Update"
            />
          </div>
        )}
      </div>
      {comments.map((comment) => (
        <Comment
          key={comment.comment_id}
          item={comment}
          onEditComment={handleEditComment}
          onDeleteComment={handleDeleteComment}
          onEditReply={handleEditReply}
          onDeleteReply={handleDeleteReply}
        />
      ))}
    </div>
  );
}

export default withRouter(Comments);
