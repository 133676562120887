import * as types from './actionTypes';

const initialState = [];
export default function team(state = initialState, action = {}) {
  switch (action.type) {
    case types.FULFILL_TEAM:
      return [...action.payload];
    default:
      return state;
  }
}
