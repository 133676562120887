import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { Box, Divider } from '@mui/material';
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR
} from 'react-redux-notify';
import Api from '../../util/Api';
import Spacer from '../../components/UI/Spacer';
import { showLoading, hideLoading } from '../../reducers/ux/actions';
import stylesMain from '../../styles/smbxStyles.module.scss'; // renamed file & updated import
import useStyles from '../../web-component-library/smbx-commons/styles/UI/buttonStyle';

function RecoverForm({ history }) {
  const dispatch = useDispatch();
  const emailRef = useRef('');
  const [formReady, setFormReady] = useState(false);
  const classes = useStyles();

  const checkFormReady = useCallback(() => {
    setFormReady(emailRef.current.value.trim() !== '');
  }, []);

  const recoverPassword = useCallback(
    async (event) => {
      event.preventDefault();
      dispatch(showLoading());
      const response = await Api.getInstance().forgotPassword(emailRef.current.value);
      dispatch(hideLoading());
      if (response && response.success) {
        dispatch(
          createNotification({
            type: NOTIFICATION_TYPE_SUCCESS,
            message: 'Check your email and follow the instructions',
            duration: 3000
          })
        );
        history.push('/signin');
      } else {
        dispatch(
          createNotification({
            type: NOTIFICATION_TYPE_ERROR,
            message: 'Error on reset password',
            duration: 2000
          })
        );
      }
    },
    [dispatch, history]
  );

  return (
    <form onSubmit={recoverPassword}>
      <Link to="/signin" className={stylesMain.main_link_secondary}>
        ◂ <u>Back</u>
      </Link>
      <Spacer vertical={20} />
      <Box className={stylesMain.main_form_title}>Forgot Password</Box>
      <Spacer vertical={20} />
      <Divider className={stylesMain.main_divider} />
      <Spacer vertical={20} />
      <label htmlFor="inputEmail">Email Address</label>
      <input
        onChange={checkFormReady}
        type="email"
        id="inputEmail"
        className={`form-control ${stylesMain.main_form_input}`}
        name="email"
        ref={emailRef}
        required
      />
      <Spacer vertical={40} />
      <Button
        className={`${classes.root} ${classes.primary} ${classes.ft16}`}
        type="submit"
        disabled={!formReady}>
        Reset Password
      </Button>
    </form>
  );
}

export default RecoverForm;
