import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import colors from '../../util/colors';
import ClosablePanel from '../../components/ClosablePanel';
import { numberWithCommas } from '../../util/functions';
import Config from '../../util/Config';
import {
  fulfillScheduledPayments,
  fullfillPaymentHistory,
  fulfillInvestors
} from '../../reducers/offering/actions';

function Settlement({ dispatch, scheduledPayments, paymentHistory, investors, ...rest }) {
  // Local state for the active tab and monthly payment info.
  const [tabActive, setTabActive] = useState(1);
  const [paymentMonthly, setPaymentMonthly] = useState({ total: 0, stored: false });

  // Read offeringSno once (assume it doesn't change during a session)
  const offeringSno = localStorage.getItem('offering_sno');

  // Fetch scheduled payments, payment history, and investors only once on mount or when offeringSno changes.
  useEffect(() => {
    if (offeringSno) {
      dispatch(fulfillScheduledPayments(offeringSno));
      dispatch(fullfillPaymentHistory(offeringSno));
      dispatch(fulfillInvestors(offeringSno));
    }
    // We only depend on dispatch and offeringSno (which is a string)
  }, [dispatch, offeringSno]);

  // Update monthly payment from scheduled payments (only once when data arrives)
  useEffect(() => {
    if (!paymentMonthly.stored && scheduledPayments && scheduledPayments.length > 0) {
      setPaymentMonthly({
        total: scheduledPayments[0].total_payment,
        stored: true
      });
    }
  }, [scheduledPayments, paymentMonthly.stored]);

  // Tab switching: use stable callbacks
  const setTab = useCallback((tab) => setTabActive(tab), []);
  const handleTab1 = useCallback(() => setTab(1), [setTab]);
  const handleTab2 = useCallback(() => setTab(2), [setTab]);
  const handleTab3 = useCallback(() => setTab(3), [setTab]);

  const handleTab1KeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') handleTab1();
    },
    [handleTab1]
  );
  const handleTab2KeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') handleTab2();
    },
    [handleTab2]
  );
  const handleTab3KeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') handleTab3();
    },
    [handleTab3]
  );

  // Render scheduled payments table
  const getScheduledPayments = useCallback(() => {
    if (!scheduledPayments || scheduledPayments.length === 0) {
      return <ClosablePanel title="No scheduled payments to display." />;
    }
    return (
      <div className="table-page">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Payment #</th>
              <th>Payment Date</th>
              <th className="text-right">Beginning Principal Balance</th>
              <th className="text-right">Principal</th>
              <th className="text-right">Interest</th>
              <th className="text-right">Payment Amount</th>
              <th className="text-right">Ending Principal Balance</th>
            </tr>
          </thead>
          <tbody>
            {scheduledPayments.map((payment, index) => (
              <tr key={`scheduled_payment_${index}`}>
                <td>{payment.payment_no}</td>
                <td>
                  {moment(payment.payment_date, 'MM-DD-YYYY').format(Config.OUTPUT_DATE_FORMAT)}
                </td>
                <td className="text-right">${numberWithCommas(payment.beginning_balance)}</td>
                <td className="text-right">${numberWithCommas(payment.principal)}</td>
                <td className="text-right">${numberWithCommas(payment.interest)}</td>
                <td className="text-right">${numberWithCommas(payment.total_payment)}</td>
                <td className="text-right">${numberWithCommas(payment.ending_balance)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }, [scheduledPayments]);

  // Render payment history table
  const getPaymentHistory = useCallback(() => {
    const { payments } = paymentHistory;
    const today = moment().tz('UTC');
    const paymentsDone = payments.filter(
      (payment) => moment(payment.payment_date.replaceAll('-', '/')).tz('UTC') < today
    );
    if (paymentsDone.length === 0) {
      return <ClosablePanel title="No payments made to date." />;
    }
    return (
      <div className="table-page">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Payment #</th>
              <th>Payment Date</th>
              <th className="text-right">Beginning Principal Balance</th>
              <th className="text-right">Principal</th>
              <th className="text-right">Interest</th>
              <th className="text-right">Payment Amount</th>
              <th className="text-right">Ending Principal Balance</th>
            </tr>
          </thead>
          <tbody>
            {paymentsDone.map((payment, index) => (
              <tr key={`payment_history_${index}`}>
                <td>{payment.payment_no}</td>
                <td>
                  {moment(payment.payment_date, 'MM-DD-YYYY').format(Config.OUTPUT_DATE_FORMAT)}
                </td>
                <td className="text-right">${numberWithCommas(payment.beginning_balance)}</td>
                <td className="text-right">${numberWithCommas(payment.principal)}</td>
                <td className="text-right">${numberWithCommas(payment.interest)}</td>
                <td className="text-right">${numberWithCommas(payment.total_payment)}</td>
                <td className="text-right">${numberWithCommas(payment.ending_balance)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }, [paymentHistory]);

  // Render investors table
  const getInvestors = useCallback(() => {
    if (!investors || investors.length === 0) {
      return <ClosablePanel title="No investors to display." />;
    }
    return (
      <div className="table-page">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Investor</th>
              <th>Location</th>
              <th className="text-right">Bonds Held</th>
              <th className="text-right">Principal Held</th>
            </tr>
          </thead>
          <tbody>
            {investors.map((item) => (
              <tr key={`investor_${item.id}`}>
                <td>
                  {item.userDetails.firstname} {item.userDetails.lastname}
                </td>
                <td>
                  {item.userDetails.city.toLowerCase()}, {item.userDetails?.shortState}
                </td>
                <td className="text-right">{item.bondnumber}</td>
                <td className="text-right">
                  $
                  {item.bondnumber
                    ? numberWithCommas(
                        (item.bondnumber * item.userDetails.security_par_value).toFixed(2)
                      )
                    : 0}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }, [investors]);

  const settlementStyles = {
    container: {
      backgroundColor: colors.BACKGROUND_GRAY,
      marginTop: 0,
      borderTop: `1px solid ${colors.LIGHT_GREY_2}`,
      padding: 0
    },
    label: {
      color: colors.DARK_GREY_2,
      fontSize: 12,
      margin: 0
    },
    text: {
      fontSize: 15,
      fontWeight: 300,
      textAlign: 'right',
      marginBottom: 0
    },
    cell: {
      borderBottom: `1px solid ${colors.LIGHT_GREY_2}`,
      padding: '13px 25px'
    },
    column: {
      borderRight: `1px solid ${colors.LIGHT_GREY_2}`
    },
    tabs: {
      marginTop: 25,
      display: 'flex',
      flexDirection: 'row'
    },
    tabActive: {
      fontSize: 15,
      fontWeight: 300,
      color: '#000',
      padding: '14px 25px',
      borderRight: '1px solid #fff',
      cursor: 'pointer',
      backgroundColor: '#fff',
      userSelect: 'none'
    },
    tab: {
      fontSize: 15,
      fontWeight: 300,
      color: colors.MID_GREYl,
      padding: '14px 25px',
      borderRight: '1px solid #fff',
      cursor: 'pointer',
      backgroundColor: colors.LIGHT_GREY_1,
      userSelect: 'none'
    }
  };

  return (
    <div>
      <div className="card-data-box">
        <div className="row">
          <div className="col">
            <div className="card text-white card-data-offering">
              <div className="card-header">Term</div>
              <h3 className="card-title">{rest.muturity_term} months</h3>
            </div>
          </div>
          <div className="col">
            <div className="card text-white card-data-offering">
              <div className="card-header">Interest Rate</div>
              <h3 className="card-title">{rest.bond_yield} %</h3>
            </div>
          </div>
          <div className="col">
            <div className="card text-white card-data-offering">
              <div className="card-header">Amount Raised</div>
              <h2 className="card-title">${numberWithCommas(rest.amount_raised)}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="smbx-content" style={settlementStyles.container}>
        <div className="row">
          <div className="col" style={settlementStyles.column}>
            <div className="row" style={settlementStyles.cell}>
              <div className="col">
                <p style={settlementStyles.label}>Start Date</p>
              </div>
              <div className="col">
                <h6 style={settlementStyles.text}>
                  {moment(rest.bond_settlement_date, Config.DATE_FORMAT).format(
                    Config.OUTPUT_DATE_FORMAT
                  )}
                </h6>
              </div>
            </div>
            <div className="row" style={settlementStyles.cell}>
              <div className="col">
                <p style={settlementStyles.label}>Maturity Date</p>
              </div>
              <div className="col">
                <h6 style={settlementStyles.text}>
                  {moment(rest.bond_settlement_date, Config.DATE_FORMAT)
                    .add(rest.muturity_term, 'M')
                    .format(Config.OUTPUT_DATE_FORMAT)}
                </h6>
              </div>
            </div>
            <div className="row" style={settlementStyles.cell}>
              <div className="col">
                <p style={settlementStyles.label}>Monthly Payment</p>
              </div>
              <div className="col">
                <h6 style={settlementStyles.text}>${numberWithCommas(paymentMonthly.total)}</h6>
              </div>
            </div>
          </div>
          <div className="col" style={settlementStyles.column}>
            <div className="row" style={settlementStyles.cell}>
              <div className="col-9">
                <p style={settlementStyles.label}>Investor Interest Rate</p>
              </div>
              <div className="col">
                <h6 style={settlementStyles.text}>{rest.bond_yield}%</h6>
              </div>
            </div>
            <div className="row" style={settlementStyles.cell}>
              <div className="col">
                <p style={settlementStyles.label}>Total Bond Repayment</p>
              </div>
            </div>
            <div className="row" style={settlementStyles.cell}>
              <div className="col-9">
                <p style={settlementStyles.label}>SMBX Capital Raise Fee</p>
              </div>
              <div className="col-3">
                <h6 style={settlementStyles.text}>{rest.financing_fee || '0'} %</h6>
              </div>
            </div>
          </div>
          <div className="col" style={settlementStyles.column}>
            <div className="row" style={settlementStyles.cell}>
              <div className="col">
                <h6 style={settlementStyles.text}>
                  ${numberWithCommas((rest.amount_raised / 100) * rest.financing_fee)}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={settlementStyles.tabs}>
        <div
          role="button"
          tabIndex={0}
          onClick={handleTab1}
          onKeyDown={handleTab1KeyDown}
          style={tabActive === 1 ? settlementStyles.tabActive : settlementStyles.tab}>
          Payment Schedule
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={handleTab2}
          onKeyDown={handleTab2KeyDown}
          style={tabActive === 2 ? settlementStyles.tabActive : settlementStyles.tab}>
          Payment History
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={handleTab3}
          onKeyDown={handleTab3KeyDown}
          style={tabActive === 3 ? settlementStyles.tabActive : settlementStyles.tab}>
          Investors
        </div>
      </div>
      <div className="smbx-content" style={{ marginTop: 0 }}>
        {tabActive === 1 && getScheduledPayments()}
        {tabActive === 2 && getPaymentHistory()}
        {tabActive === 3 && getInvestors()}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { offering } = state;
  return {
    revenue: offering.revenue,
    scheduledPayments: offering.scheduledPayments,
    paymentHistory: offering.paymentHistory,
    investors: offering.investors,
    payments: offering.payments,
    monthly_payment: offering.monthly_payment,
    bond_issued: offering.bond_issued,
    amount_raised: offering.amount_raised,
    muturity_term: offering.muturity_term,
    bond_settlement_date: offering.bond_settlement_date,
    financing_fee: offering.financing_fee,
    bond_yield: offering.yield,
    auction_end_date_time: offering.auction_end_date_time
  };
};

export default connect(mapStateToProps)(Settlement);
