/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

function BEPagination({
  items,
  onChangePage,
  initialPage,
  pageSize,
  seePagination,
  totalRecords,
  currentPage
}) {
  const [pager, setPager] = useState({});

  const getPager = useCallback((totalItems, page) => {
    const actualPage = page || 1;
    const actualPageSize = pageSize || 10;
    const totalPages = Math.ceil(totalRecords / actualPageSize);
    let startPage;
    let endPage;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else if (actualPage <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (actualPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = actualPage - 5;
      endPage = actualPage + 4;
    }
    const startIndex = (actualPage - 1) * actualPageSize;
    const endIndex = Math.min(startIndex + actualPageSize - 1, totalItems - 1);
    const pages = Array.from({ length: endPage + 1 - startPage }, (_, i) => startPage + i);
    return {
      totalItems,
      currentPage: actualPage,
      pageSize: actualPageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
      seePagination,
      totalRecords
    };
  }, []);

  const setPage = useCallback(
    (page) => {
      if (!items || items.length === 0) return;
      if (pager.totalPages && (page < 1 || page > pager.totalPages)) return;
      const newPager = getPager(items.length, page, pageSize, seePagination, totalRecords);
      setPager(newPager);
      const pageOfItems = items.slice(newPager.startIndex, newPager.endIndex + 1);
      onChangePage(pageOfItems, page - 1);
    },
    [items, pageSize, seePagination, totalRecords, pager.totalPages, getPager, onChangePage]
  );

  useEffect(() => {
    if (items && items.length > 0) {
      setPage(initialPage);
    }
  }, [items, initialPage, setPage]);

  useEffect(() => {
    setPage(currentPage || initialPage);
  }, [items, totalRecords, currentPage, initialPage, setPage]);

  if (!pager.totalRecords) {
    return <span className="no-orders">No orders to display</span>;
  }

  return (
    <div className="page-container">
      <span className="per-page">Items per page: {pager.pageSize}</span>
      <span className="per-page">
        {pager.currentPage * pager.pageSize - (pager.pageSize - 1)} -{' '}
        {pager.currentPage * pager.pageSize > pager.totalRecords
          ? pager.totalRecords
          : pager.currentPage * pager.pageSize}{' '}
        of {pager.totalRecords}
      </span>
      <ul className="pagination">
        <li>
          <button
            type="button"
            onClick={() => setPage(1)}
            disabled={pager.currentPage === 1}
            className="page-link"
            aria-label="First Page">
            <span className="material-icons">first_page</span>
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => setPage(pager.currentPage - 1)}
            disabled={pager.currentPage === 1}
            className="page-link"
            aria-label="Previous Page">
            <span className="material-icons">keyboard_arrow_left</span>
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => setPage(pager.currentPage + 1)}
            disabled={pager.currentPage === pager.totalPages}
            className="page-link"
            aria-label="Next Page">
            <span className="material-icons">keyboard_arrow_right</span>
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => setPage(pager.totalPages)}
            disabled={pager.currentPage === pager.totalPages}
            className="page-link"
            aria-label="Last Page">
            <span className="material-icons">last_page</span>
          </button>
        </li>
      </ul>
    </div>
  );
}

BEPagination.propTypes = {
  items: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
  seePagination: PropTypes.bool,
  totalRecords: PropTypes.number,
  currentPage: PropTypes.number
};

BEPagination.defaultProps = {
  initialPage: 1,
  pageSize: 10,
  seePagination: false,
  totalRecords: 0,
  currentPage: 0
};

export default React.memo(BEPagination);
