import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR
} from 'react-redux-notify';
import LogRocket from 'logrocket';
import Api from '../../util/Api';
import * as types from './actionTypes';
import { showLoading, hideLoading } from '../ux/actions';
import Config from '../../util/Config';

export const signin = (username, password, history, lastLocation) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await Api.getInstance().signin(username, password);
    dispatch(hideLoading());
    if (response.success) {
      if (response.data.role === 'issuer') {
        dispatch({ type: types.LOGIN_SUCCESS, payload: { ...response.data } });
        dispatch(
          createNotification({
            type: NOTIFICATION_TYPE_SUCCESS,
            message: `Welcome ${username}`,
            duration: 1000
          })
        );
        const userData = await Api.getInstance().getUser();
        if (userData.success) {
          if (Config.LOGROCKET_SERVICE !== '') {
            LogRocket.identify(userData.data.data.userId, {
              username: userData.data.data.user_name,
              email: userData.data.data.email,
              emailVerified: userData.data.data.emailVerified
            });
          }
        }
        if (lastLocation && lastLocation !== '') {
          history.push(lastLocation);
        } else {
          history.push('/app');
        }
      } else {
        dispatch(
          createNotification({
            type: NOTIFICATION_TYPE_ERROR,
            message: 'You are not an Issuer user',
            duration: 2000
          })
        );
      }
    } else {
      dispatch({ type: types.LOGIN_FAILED });
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  } catch (error) {
    dispatch(hideLoading());
  }
};

export const setUser = (user) => ({ type: types.SET_USER, payload: user });

export const checkToken =
  (history, callback = () => {}) =>
  async (dispatch) => {
    dispatch({ type: types.SET_CHECKSTATUS, payload: { checkstatus: true } });
    dispatch(showLoading());
    try {
      const response = await Api.getInstance().checkToken();
      if (!response.success) {
        callback();
        dispatch(signout(history));
      }
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
    }
  };

export const updateImage = (profilePicture) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await Api.getInstance().updatePicture(profilePicture);
    if (response.success) {
      dispatch({ type: types.UPDATE_IMAGE, payload: { ...response.data } });
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Picture profile updated',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
  }
};

export const signout = (history) => {
  history.push('/signin');
  return { type: types.SIGNOUT };
};
