import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import smbxLogo from '../assets/img/smbx-logo.svg';
import smbxLogoSmall from '../assets/img/smbx-logo-small.svg';

function Sidenav({ location }) {
  const menus = [
    { label: 'Bond Offering', icon: 'local_atm', path: '/app' },
    { label: 'Comments', icon: 'chat', path: '/app/comments' },
    { label: 'Documents', icon: 'library_books', path: '/app/documents' }
  ];

  const [width, setWidth] = useState(window.innerWidth - 100);

  const updateDimensions = useCallback(() => {
    if (window.innerWidth < 500) {
      setWidth(450);
    } else {
      setWidth(window.innerWidth - 100);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [updateDimensions]);

  const toggleNav = useCallback(() => {
    setWidth((prev) => (prev <= 1073 ? 1074 : 1073));
  }, []);

  const handleToggleNavKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') toggleNav();
    },
    [toggleNav]
  );

  const renderMenuItem = useCallback(
    (item, index) => (
      <li key={`menu_${index}`}>
        <Link to={item.path} className={item.path === location.pathname ? 'active' : ''}>
          <i className="material-icons">{item.icon}</i>
          <span>{item.label}</span>
        </Link>
      </li>
    ),
    [location.pathname]
  );

  const navClass = width <= 1073 ? 'col no-gutters nav-col nav-closed' : 'col no-gutters nav-col';
  return (
    <nav className={navClass}>
      <div className="nav-left">
        <span
          role="button"
          onClick={toggleNav}
          onKeyDown={handleToggleNavKeyDown}
          tabIndex={0}
          style={{ cursor: 'pointer', paddingLeft: '10px' }}>
          <img className="logo" src={smbxLogo} alt="logo" />
        </span>
        {/* Changed element from <ul> to <div> to ensure it is treated as interactive */}
        <div
          role="button"
          onClick={toggleNav}
          onKeyDown={handleToggleNavKeyDown}
          tabIndex={0} // <-- Added tabIndex here
          style={{ cursor: 'pointer', paddingLeft: '10px' }}>
          <img className="logo-small" src={smbxLogoSmall} alt="logo_small" />
        </div>
        <ul>{menus.map(renderMenuItem)}</ul>
      </div>
    </nav>
  );
}

export default withRouter(React.memo(Sidenav));
