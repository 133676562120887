import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../colors';

const useStyles = makeStyles({
  // common style for a button
  root: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    width: '100%',
    height: '40px',
    borderRadius: 6,
    letterSpacing: '.5px',
    textTransform: 'capitalize',
    color: colors.grey100
  },
  primary: {
    backgroundColor: colors.primary100,
    '&:hover': {
      backgroundColor: colors.primary070
    },
    '&:disabled': {
      color: colors.grey070,
      backgroundColor: colors.grey030
    }
  },
  secondary: {
    backgroundColor: colors.white100,
    border: `2px solid ${colors.primary100}`,
    '&:hover': {
      borderColor: colors.primary070,
      backgroundColor: colors.white100
    },
    '&:disabled': {
      color: colors.grey070,
      backgroundColor: colors.grey030,
      border: 0
    }
  },
  // font sizes
  ft18: { fontSize: '18px' },
  ft16: { fontSize: '16px' },
  ft14: { fontSize: '14px' }
});

export default useStyles;
