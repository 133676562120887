import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { signout } from '../reducers/user/actions';
import Ticker from '../web-component-library/smbx-commons/Ticker';
import defaultAvatar from '../assets/img/default-avatar.png';

function Topbar(props) {
  const { companyName, symbol, asyncLoading, name, avatar, dispatch, history } = props;

  const handleSignout = useCallback(() => {
    dispatch(signout(history));
  }, [dispatch, history]);

  const handleSignoutKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') handleSignout();
    },
    [handleSignout]
  );

  const displayAvatar = avatar ? (
    <img
      src={avatar}
      style={{ width: 30, height: 30, borderRadius: 15, marginLeft: 10 }}
      alt="User Avatar"
    />
  ) : (
    <img
      src={defaultAvatar}
      style={{ height: 30, marginLeft: 10, paddingBottom: 5 }}
      alt="User Avatar"
    />
  );

  return (
    <div className="container-fluid top-bar">
      <div className="row">
        <div className="col no-gutters col-excode">
          <span className="excode">
            {symbol.split('').map((value, index) => (
              <Ticker key={index} ticker={value} />
            ))}
          </span>
        </div>
        <div className="col no-gutters company-bond-name">
          <h1>{companyName}</h1>
        </div>
        <div className="col-4 no-gutters">
          <div className="row">
            <div className="col-4">
              {asyncLoading && (
                <ReactLoading
                  type="spin"
                  color="#000"
                  height={30}
                  width={30}
                  className="float-right"
                />
              )}
            </div>
            <div className="col-8">
              <div className="username float-right" style={{ cursor: 'pointer' }}>
                <span className="h6">
                  {name}
                  {displayAvatar}
                </span>
                <div className="username-dropdown">
                  <div className="shadow">
                    <Link to="/app/settings">Account Settings</Link>
                    <span
                      role="button"
                      onClick={handleSignout}
                      onKeyDown={handleSignoutKeyDown}
                      tabIndex={0}
                      className="signout">
                      Sign Out
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  companyName: state.offering.companyName,
  symbol: state.offering.symbol,
  asyncLoading: state.ux.asyncLoading,
  name: state.user.name,
  avatar: state.user.avatar
});

export default withRouter(connect(mapStateToProps)(Topbar));
