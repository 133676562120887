import React from 'react';
import colors from '../util/colors';
import { wholeNumberWithCommas } from '../util/functions';

function Progressbar({ limit, reached }) {
  const percentage = Math.min((reached / limit) * 100, 100);
  return (
    <div>
      <p style={{ color: colors.MID_GREYl, marginBottom: 5 }}>
        <span style={{ color: '#000' }}>{wholeNumberWithCommas(reached)}</span> /{' '}
        {wholeNumberWithCommas(limit)} bonds
      </p>
      <div style={{ backgroundColor: colors.LIGHT_GREY_1, height: 15, position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            height: 15,
            left: 0,
            backgroundColor: colors.AUCTION,
            borderRight: '2px solid #fff',
            width: `${percentage}%`
          }}
        />
      </div>
    </div>
  );
}

export default React.memo(Progressbar);
