import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import notifyReducer from 'react-redux-notify';
import moment from 'moment-timezone';
import * as reducers from './reducers';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Config from './util/Config';

// Styles
import 'react-redux-notify/dist/ReactReduxNotify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/bootstrap.scss';

const rootReducer = combineReducers({
  ...reducers,
  notifications: notifyReducer
});
const store = createStore(rootReducer, applyMiddleware(thunk));

moment.tz.setDefault('America/Los_Angeles');

fetch(`${window.location.protocol}//${window.location.host}/apiendpoint.json`)
  .then((response) => response.json())
  .then((data) => {
    Config.SERVER = data.serverAPIURL;
    Config.API_SERVER = `${data.serverAPIURL}/api/`;
    Config.LOGROCKET_SERVICE = data.logrocket_service;

    const root = ReactDOMClient.createRoot(document.getElementById('root'));
    root.render(
      <Provider store={store}>
        <App />
      </Provider>
    );

    // To enable offline capabilities, change unregister() to register()
    serviceWorker.unregister();
  })
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.error('Error fetching configuration:', error);
  });
