import * as types from './actionTypes';
import Api from '../../util/Api';

const initialState = {
  name: '',
  avatar: '',
  checkstatus: false
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      Api.setInstance(action.payload.access_token);
      localStorage.setItem('token', action.payload.access_token);
      localStorage.setItem('name', action.payload.name);
      localStorage.setItem('avatar', action.payload.avatar);
      if (action.payload.offering_sno?.[0]) {
        localStorage.setItem('offering_sno', action.payload.offering_sno[0]);
      }
      if (action.payload.company_sno?.[0]) {
        localStorage.setItem('company_sno', action.payload.company_sno[0]);
      }
      return { ...state, name: action.payload.name, avatar: action.payload.avatar };
    case types.LOGIN_FAILED:
      return state;
    case types.SET_CHECKSTATUS:
      return { ...state, checkstatus: action.payload.checkstatus };
    case types.SET_USER:
      return { ...state, name: action.payload.name, avatar: action.payload.avatar };
    case types.SIGNOUT:
      Api.setInstance(null);
      localStorage.removeItem('token');
      localStorage.removeItem('offering_sno');
      localStorage.removeItem('name');
      localStorage.removeItem('avatar');
      localStorage.removeItem('company_sno');
      return initialState;
    case types.UPDATE_IMAGE:
      localStorage.setItem('avatar', action.payload.user_image);
      return { ...state, avatar: action.payload.user_image };
    default:
      return state;
  }
}
