import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createNotification, NOTIFICATION_TYPE_ERROR } from 'react-redux-notify';
import SigninForm from './SigninForm';
import RecoverForm from './RecoverForm';
import Spacer from '../../components/UI/Spacer';
import styles from '../../styles/smbxStyles.module.scss'; // import the default export
import logo from '../../assets/img/smbx-logo.svg';

function Signin(props) {
  const { search, history, location } = props;
  const dispatch = useDispatch();
  const params = new URLSearchParams(search);
  const forgotPassword = params.get('recover');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const expired = query.get('expired');
    if (expired) {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: 'Session Expired',
          duration: 2000
        })
      );
      history.push('/signin');
    }
  }, [dispatch, history, location.search]);

  return (
    <Box className={styles.main}>
      <Box className={styles.header_content}>
        <Box className={styles.header_logo_centered}>
          <img src={logo} alt="smbx-logo" />
        </Box>
      </Box>
      <Box className={styles.main_content}>
        <Spacer vertical={55} />
        <Box className={styles.main_bussiness}>Issuer Portal</Box>
        <Spacer vertical={50} />
        <Box className={styles.main_centered}>
          <Box className={styles.main_form}>
            <Box className={styles.main_form_borderTop} />
            {forgotPassword ? (
              <RecoverForm history={history} location={location} />
            ) : (
              <SigninForm history={history} location={location} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Signin;
