import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import { postReply } from '../reducers/comments/actions';
import Config from '../util/Config';
import colors from '../util/colors';
import Reply from './Reply';
import ReplyForm from './ReplyForm';
import fallbackImg from '../assets/img/f8e71c.png';

function Comment({ item, onEditComment, onDeleteComment, onEditReply, onDeleteReply }) {
  const dispatch = useDispatch();

  const handleEditComment = useCallback(() => {
    onEditComment(item);
  }, [item, onEditComment]);

  const handleDeleteComment = useCallback(() => {
    onDeleteComment(item.comment_id);
  }, [item.comment_id, onDeleteComment]);

  const handlePostReply = useCallback(
    (commentValue) => {
      dispatch(postReply(commentValue, item.comment_id));
    },
    [dispatch, item.comment_id]
  );

  const handleEditKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') handleEditComment();
    },
    [handleEditComment]
  );

  const handleDeleteKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') handleDeleteComment();
    },
    [handleDeleteComment]
  );

  return (
    <div style={{ backgroundColor: '#fff', marginTop: 25 }}>
      <div className="post">
        <div className="row">
          <div className="col-11 p-4">
            <h6>
              <img
                src={item.profile_image}
                width="30"
                height="30"
                alt="User Profile"
                onError={(e) => {
                  e.target.src = fallbackImg;
                }}
              />
              &nbsp;{item.username}
              <br />
              <br />
            </h6>
            <p>
              {parse(item.comment)}
              <br />
              <br />
            </p>
            <div className="float-left">
              <p>
                {moment(item.commented_at, Config.DATE_FORMAT).format(Config.OUTPUT_DATE_FORMAT)}
              </p>
            </div>
            {item.isOwner && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                className="float-right">
                <div
                  onClick={handleEditComment}
                  onKeyDown={handleEditKeyDown}
                  role="button"
                  tabIndex={0}
                  style={{ cursor: 'pointer' }}
                  className="action-info">
                  <i className="material-icons">edit</i>
                  <label>Edit</label>
                </div>
                <div
                  onClick={handleDeleteComment}
                  onKeyDown={handleDeleteKeyDown}
                  role="button"
                  tabIndex={0}
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                  className="action-delete">
                  <i className="material-icons">delete</i>
                  <label>Delete</label>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {item.sub_comments &&
        item.sub_comments.map((reply) => (
          <Reply
            key={reply.comment_id}
            item={reply}
            parentId={item.comment_id}
            onEditReply={onEditReply}
            onDeleteReply={onDeleteReply}
          />
        ))}
      <div
        className="smbx-content createReply"
        style={{ backgroundColor: colors.BACKGROUND_GRAY, marginTop: 0 }}>
        <ReplyForm editValue="" onSubmitForm={handlePostReply} buttonName="Reply" />
      </div>
    </div>
  );
}

export default React.memo(Comment);
