// offering.reducer.js
import * as types from './actionTypes';

const initialState = {
  company_name: '',
  raise: 0,
  yield: '',
  symbol: '',
  status: -1,
  payments: '',
  percent_subscribed: 0,
  revenue: 0,
  placedBids: [],
  total_bids: 0,
  total_sold: 0,
  scheduledPayments: [],
  paymentHistory: [],
  investors: [],
  monthly_payment: 0,
  bond_issued: 0,
  secondary_market_overview: {},
  accrued_value: '',
  amount_raised: 0,
  bond_settlement_date: '',
  bond_yield: '',
  differenceInInterest: '',
  earnings_to_maturity: 0,
  financing_fee: 0,
  max_yield: 0,
  min_yield: 0,
  muturity_term: 60,
  par_value: 0,
  payment_amount: 0,
  payment_left: 0,
  payment_sequence: '',
  payment_term: 0,
  status_name: '',
  overviewFetched: false
};

export default function offering(state = initialState, action = {}) {
  switch (action.type) {
    case types.FULFILL_OVERVIEW: {
      if (action.payload.before_secondary_market) {
        return {
          ...state,
          ...action.payload.before_secondary_market,
          secondary_market_overview: action.payload.secondary_market_overview,
          overviewFetched: true
        };
      }
      return { ...state, ...action.payload, overviewFetched: true };
    }
    case types.FULFILL_PLACED_BIDS:
      // If the API call returns batch === -1 (the duplicate call) and we already have bids, ignore it.
      if (action.payload.batch === -1 && state.placedBids && state.placedBids.length > 0) {
        return state;
      }
      return {
        ...state,
        placedBids: action.payload.placed_bids,
        total_bids: action.payload.elements
      };
    case types.FULFILL_SCHEDULED_PAYMENTS:
      return { ...state, scheduledPayments: action.payload };
    case types.FULFILL_PAYMENT_HISTORY:
      return { ...state, paymentHistory: action.payload };
    case types.FULFILL_INVESTORS:
      return { ...state, investors: action.payload };
    default:
      return state;
  }
}
