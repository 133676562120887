import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import pdfImg from '../assets/img/pdf.svg';
import downloadIconImg from '../assets/img/iconDownload.svg';
import { fulfillDocuments, getForms } from '../reducers/documents/actions';

class Documents extends Component {
  static defaultProps = {
    documents: [],
    forms: []
  };

  componentDidMount() {
    const companySno = localStorage.getItem('company_sno');
    this.props.dispatch(fulfillDocuments(companySno));
    this.props.dispatch(getForms());
  }

  // Re-fetch documents when the route location changes
  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      const companySno = localStorage.getItem('company_sno');
      this.props.dispatch(fulfillDocuments(companySno));
      this.props.dispatch(getForms());
    }
  }

  // Returns a handler function for both click and keydown events
  handleFileOpen = (file) => (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter')) {
      this.openFile(file);
    }
  };

  openFile(path) {
    window.open(path, '_blank');
  }

  renderDocument(item, index) {
    return (
      <div className="row" key={`document_${index}`}>
        <div className="col">
          <img src={pdfImg} alt=".pdf document" />
          <span className="p">{item.name}</span>
        </div>
        <div className="col-2 text-right">
          <button
            type="button"
            onClick={this.handleFileOpen(item.file)}
            onKeyDown={this.handleFileOpen(item.file)}
            className="download-icon"
            style={{ background: 'none', border: 'none', padding: 0 }}>
            <img src={downloadIconImg} alt="download" />
          </button>
        </div>
      </div>
    );
  }

  renderForm(item, index) {
    return (
      <div className="row" key={`form_${index}`}>
        <div className="col">
          <img src={pdfImg} alt=".pdf 1099 form" />
          <span className="p">{item.fileName}</span>
          <span className="p">{item.fileSize}</span>
        </div>
        <div className="col-2 text-right">
          <button
            type="button"
            onClick={this.handleFileOpen(item.fileUrl)}
            onKeyDown={this.handleFileOpen(item.fileUrl)}
            className="download-icon"
            style={{ background: 'none', border: 'none', padding: 0 }}>
            <img src={downloadIconImg} alt="download" />
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="documents-box">
        <div className="container">
          <div className="company-cover" />
        </div>
        <div className="smbx-content documents container">
          <div className="document-list">
            <div className="row">
              <div className="col">
                <h4 className="documents-title"> Documents </h4>
              </div>
            </div>
            <div className="file-row">
              {this.props.documents.length > 0 ? (
                this.props.documents.map(this.renderDocument.bind(this))
              ) : (
                <span>There are no documents to view.</span>
              )}
            </div>
          </div>
        </div>
        <div className="smbx-content documents container">
          <div className="document-list">
            <div className="row">
              <div className="col">
                <h4 className="documents-title"> 1099 Forms </h4>
              </div>
            </div>
            <div className="file-row">
              {this.props.forms.length > 0 ? (
                this.props.forms.map(this.renderForm.bind(this))
              ) : (
                <span>There are no 1099 forms to view.</span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    documents: state.documents.documents,
    forms: state.documents.forms
  };
}

export default withRouter(connect(mapStateToProps)(Documents));
