import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Notify } from 'react-redux-notify';
import { connect, useDispatch } from 'react-redux';
import LogRocket from 'logrocket';
import Api from './util/Api';
import Config from './util/Config';
import { setUser } from './reducers/user/actions';

// Components
import Loading from './components/Loading';
import Confirm from './components/Confirm';

// Pages
import Signin from './views/Signin/Signin';
import Home from './views/Home';

// AlreadySignedIn: if no token is found, show the component; otherwise, redirect to "/app"
function AlreadySignedIn({ component: Component, ...rest }) {
  const renderAlreadySignedIn = useCallback(
    (routeProps) =>
      !localStorage.getItem('token') ? <Component {...routeProps} /> : <Redirect to="/app" />,
    [Component]
  );
  return <Route {...rest} render={renderAlreadySignedIn} />;
}

// PrivateRoute: if token is found, render the component; otherwise, redirect to "/signin"
function PrivateRoute({ component: Component, ...rest }) {
  const renderPrivate = useCallback(
    (routeProps) =>
      localStorage.getItem('token') ? (
        <Component {...routeProps} />
      ) : (
        <Redirect to={{ pathname: '/signin', state: routeProps.location.pathname }} />
      ),
    [Component]
  );
  return <Route {...rest} render={renderPrivate} />;
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      Api.setInstance(token);
    }
    const name = localStorage.getItem('name');
    const avatar = localStorage.getItem('avatar');
    if (name) {
      dispatch(setUser({ name, avatar }));
    }
    if (Config.LOGROCKET_SERVICE !== '') {
      LogRocket.init(Config.LOGROCKET_SERVICE);
    }
  }, [dispatch]);

  const renderNoMatch = useCallback(
    (routeProps) => (
      <div>
        No match for <code>{routeProps.location.pathname}</code>
      </div>
    ),
    []
  );

  return (
    <Router>
      <div>
        <Confirm />
        <Notify />
        <Loading />
        <Switch>
          <AlreadySignedIn path="/signin" component={Signin} />
          <Redirect exact from="/app/company" to="/app" />
          <Redirect exact from="/app/about" to="/app" />
          <PrivateRoute path="/app" component={Home} />
          <Redirect exact from="/" to="/app" />
          <Route render={renderNoMatch} />
        </Switch>
      </div>
    </Router>
  );
}

export default connect()(App);
