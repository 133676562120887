import React from 'react';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import colors from '../util/colors';

function Loading() {
  const loading = useSelector((state) => state.ux.loading);
  if (!loading) return null;
  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100vh',
        zIndex: 3,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.3)'
      }}>
      <ReactLoading type="cubes" color={colors.YELLOW} height={100} width={100} />
    </div>
  );
}

export default React.memo(Loading);
