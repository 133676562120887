import { createNotification, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify';
import Api from '../../util/Api';
import { showLoading, hideLoading } from '../ux/actions';
import * as types from './actionTypes';

export const fulfillDocuments = (companySno) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const token = localStorage.getItem('token');
    if (token) {
      Api.setInstance(token);
    }
    const response = await Api.getInstance().getDocuments(companySno);
    dispatch(hideLoading());
    if (response.success) {
      dispatch({ type: types.FULFILL_DOCUMENTS, payload: response.data });
    }
  } catch (error) {
    dispatch(hideLoading());
  }
};

export const getForms = () => async (dispatch) => {
  dispatch(showLoading());
  try {
    const token = localStorage.getItem('token');
    if (token) {
      Api.setInstance(token);
    }
    const response = await Api.getInstance().getForms();
    dispatch(hideLoading());
    if (response.success) {
      dispatch({ type: types.GET_FORMS, payload: response.data });
    }
  } catch (error) {
    dispatch(hideLoading());
  }
};

export const uploadDocument = (document, companySno) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const token = localStorage.getItem('token');
    if (token) {
      Api.setInstance(token);
    }
    const response = await Api.getInstance().uploadDocument(document, companySno);
    dispatch(hideLoading());
    if (response.success) {
      dispatch({ type: types.FULFILL_DOCUMENTS, payload: response.data.documents });
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Document uploaded',
          duration: 1000
        })
      );
    }
  } catch (error) {
    dispatch(hideLoading());
  }
};

export const deleteDocument = (companySno, id) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const token = localStorage.getItem('token');
    if (token) {
      Api.setInstance(token);
    }
    const response = await Api.getInstance().deleteDocument(companySno, id);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fulfillDocuments(companySno));
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Document deleted',
          duration: 1000
        })
      );
    }
  } catch (error) {
    dispatch(hideLoading());
  }
};
