/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, connect } from 'react-redux';
import moment from 'moment-timezone';
import { fulfillPlacedBids } from '../../reducers/offering/actions';
import { wholeNumberWithCommas } from '../../util/functions';
import Config from '../../util/Config';
import Progressbar from '../../components/Progressbar';
import ClosablePanel from '../../components/ClosablePanel';
import BEPagination from '../../components/BEpagination';

function Auction(props) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);

  // On mount or when currentPage changes, dispatch the thunk.
  useEffect(() => {
    dispatch(fulfillPlacedBids(currentPage));
  }, [dispatch, currentPage]);

  const renderBid = useCallback(
    (item, index) => (
      <tr key={`Placed_bids_${index}`}>
        <td>
          <p>{moment(item.created_at, Config.DATE_FORMAT).fromNow()}</p>
        </td>
        <td>{item.user_fullname}</td>
        <td className="text-right">{wholeNumberWithCommas(item.number)}</td>
        <td className="text-right">
          ${wholeNumberWithCommas(item.number * item.principal_amount)}
        </td>
      </tr>
    ),
    []
  );

  // BEPagination is 1-indexed
  const onChangePage = useCallback(
    (_, pageChanged) => {
      const newPage = pageChanged - 1;
      if (newPage !== currentPage) {
        setCurrentPage(newPage);
      }
    },
    [currentPage]
  );

  const {
    percent_subscribed,
    maximumAmountBeingRaised,
    placedBids,
    total_sold,
    status,
    auction_end_date_time,
    yield: bondYield,
    payments
  } = props;

  return (
    <div>
      {/* Stats Header */}
      <div className="stats">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              padding: '15px 25px',
              backgroundColor: status === 1 ? '#dedede' : '#000',
              color: '#fff',
              fontSize: 15
            }}>
            Offering Started
          </div>
          <span style={{ fontSize: 15, fontFamily: 'Lato', fontWeight: 400, marginLeft: 25 }}>
            Offering Closes on {moment(auction_end_date_time, Config.DATE_FORMAT).format('LLL')}{' '}
            Pacific
          </span>
        </div>
      </div>

      {/* Overview Cards */}
      <div className="card-data-box">
        <div className="row">
          <div className="col">
            <div className="card text-white card-data-offering">
              <div className="card-header">Target Offering</div>
              <h2 className="card-title">${wholeNumberWithCommas(maximumAmountBeingRaised)}</h2>
            </div>
          </div>
          <div className="col">
            <div className="card text-white card-data-offering">
              <div className="card-header">Interest Rate</div>
              <h3 className="card-title">{bondYield} %</h3>
            </div>
          </div>
          <div className="col">
            <div className="card text-white card-data-offering">
              <div className="card-header">Term</div>
              <h3 className="card-title">{payments} months</h3>
            </div>
          </div>
        </div>
      </div>

      {/* Raised Amount & Progress */}
      <div className="card-data-box">
        <div className="row">
          <div className="col">
            <div className="card text-white card-data-offering" style={{ borderRightWidth: 0 }}>
              <div className="card-header">Amount Raised</div>
              <h3 className="card-title">${wholeNumberWithCommas(total_sold * 10)}</h3>
            </div>
          </div>
          <div className="col">
            <div
              className="card text-white card-data-offering"
              style={{ borderRightWidth: 0, paddingTop: 25 }}>
              <Progressbar limit={maximumAmountBeingRaised / 10} reached={total_sold} />
            </div>
          </div>
          <div className="col">
            <div
              className="card text-white card-data-offering"
              style={{ borderRightWidth: 0, paddingTop: 50 }}>
              <p style={{ color: '#000' }}>
                {wholeNumberWithCommas(percent_subscribed)}% subscribed
              </p>
            </div>
          </div>
        </div>
      </div>

      {placedBids && placedBids.length === 1 && (
        <ClosablePanel title="Congratulations! You have received your first bid." />
      )}

      {/* Bid Table */}
      <div className="smbx-content">
        <div className="row">
          <div className="col">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Investor</th>
                  <th className="text-right">Bonds</th>
                  <th className="text-right">Amount</th>
                </tr>
              </thead>
              {placedBids && placedBids.length > 0 ? (
                <tbody>{placedBids.map(renderBid)}</tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="4">No bids to display</td>
                  </tr>
                </tbody>
              )}
            </table>
            {placedBids && placedBids.length > 0 && (
              <BEPagination
                currentPage={currentPage + 1}
                totalRecords={props.placedBidsElements} // ensure this prop is correctly set
                seePagination
                pageSize={100}
                items={placedBids}
                onChangePage={onChangePage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { offering } = state;
  return {
    status: offering.status,
    status_label: offering.status_label,
    auction_end_date_time: offering.auction_end_date_time,
    yield: offering.yield,
    payments: offering.payments,
    maximumAmountBeingRaised: offering.maximumAmountBeingRaised,
    placedBids: offering.placedBids, // now an array
    // Ensure you also pass any additional info (like total elements) if needed
    placedBidsElements: offering.total_bids,
    total_sold: offering.total_sold,
    percent_subscribed: offering.percent_subscribed
  };
};

export default connect(mapStateToProps)(Auction);
