/* eslint-disable camelcase */
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR
} from 'react-redux-notify';
import Api from '../../util/Api';
import { showLoading, hideLoading } from '../ux/actions';
import * as types from './actionTypes';

// Helpers: always use full loading
const showFullLoading = (dispatch) => dispatch(showLoading());
const hideFullLoading = (dispatch) => dispatch(hideLoading());

export const fulfillOverview =
  (offeringSno = localStorage.getItem('offering_sno')) =>
  async (dispatch, getState) => {
    // Guard: only fetch if not already fetched
    if (getState().offering.overviewFetched) return;
    showFullLoading(dispatch);
    try {
      const token = localStorage.getItem('token');
      if (token) Api.setInstance(token);
      const response = await Api.getInstance().getOfferingOverview(offeringSno);
      hideFullLoading(dispatch);
      if (response.success) {
        dispatch({
          type: types.FULFILL_OVERVIEW,
          payload: { ...response.data, overviewFetched: true }
        });
      }
    } catch (error) {
      hideFullLoading(dispatch);
    }
  };

export const fulfillPlacedBids =
  (page = 0, offeringSno = localStorage.getItem('offering_sno')) =>
  async (dispatch, getState) => {
    // For page 0, if data already exists, skip the API call.
    if (page === 0) {
      const currentPlacedBids = getState().offering.placedBids;
      if (Array.isArray(currentPlacedBids) && currentPlacedBids.length > 0) {
        return null; // Data already loaded.
      }
    }

    dispatch(showLoading());
    try {
      const token = localStorage.getItem('token');
      if (token) {
        Api.setInstance(token);
      }
      const response = await Api.getInstance().getPlacedBids(offeringSno, page);
      dispatch(hideLoading());
      if (response.success) {
        // If page 0 is requested and the API returns an empty array, do nothing.
        if (
          page === 0 &&
          response.data &&
          response.data.placed_bids &&
          response.data.placed_bids.length === 0
        ) {
          return null;
        }
        dispatch({ type: types.FULFILL_PLACED_BIDS, payload: response.data });
        return response.data;
      }
    } catch (error) {
      dispatch(hideLoading());
    }
    return null;
  };

export const fulfillScheduledPayments =
  (offeringSno = localStorage.getItem('offering_sno')) =>
  async (dispatch) => {
    showFullLoading(dispatch);
    try {
      const token = localStorage.getItem('token');
      if (token) Api.setInstance(token);
      const response = await Api.getInstance().getSchedulePayments(offeringSno);
      hideFullLoading(dispatch);
      if (response.success) {
        const payments = response.data.payments || [];
        dispatch({ type: types.FULFILL_SCHEDULED_PAYMENTS, payload: payments });
      }
    } catch (error) {
      hideFullLoading(dispatch);
    }
  };

export const fullfillPaymentHistory =
  (offeringSno = localStorage.getItem('offering_sno')) =>
  async (dispatch) => {
    showFullLoading(dispatch);
    try {
      const token = localStorage.getItem('token');
      if (token) Api.setInstance(token);
      const response = await Api.getInstance().getHistoryPayments(offeringSno);
      hideFullLoading(dispatch);
      if (response.success) {
        dispatch({ type: types.FULFILL_PAYMENT_HISTORY, payload: response.data });
      }
    } catch (error) {
      hideFullLoading(dispatch);
    }
  };

export const fulfillInvestors =
  (offeringSno = localStorage.getItem('offering_sno')) =>
  async (dispatch) => {
    showFullLoading(dispatch);
    try {
      const token = localStorage.getItem('token');
      if (token) Api.setInstance(token);
      const response = await Api.getInstance().getInvestors(offeringSno);
      hideFullLoading(dispatch);
      if (response.success) {
        dispatch({ type: types.FULFILL_INVESTORS, payload: response.data });
      }
    } catch (error) {
      hideFullLoading(dispatch);
    }
  };

export const changeState =
  (action, offeringSno = localStorage.getItem('offering_sno')) =>
  async (dispatch) => {
    showFullLoading(dispatch);
    try {
      const token = localStorage.getItem('token');
      if (token) Api.setInstance(token);
      const response = await Api.getInstance().updateAuctionStatus(offeringSno, action);
      hideFullLoading(dispatch);
      if (response.success) {
        dispatch(
          createNotification({
            type: NOTIFICATION_TYPE_SUCCESS,
            message: 'State Updated',
            duration: 1000
          })
        );
        // Refresh overview after state change
        dispatch(fulfillOverview(offeringSno));
      } else {
        dispatch(
          createNotification({
            type: NOTIFICATION_TYPE_ERROR,
            message: response.message,
            duration: 2000
          })
        );
      }
    } catch (error) {
      hideFullLoading(dispatch);
    }
  };
