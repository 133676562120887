import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidenav from '../components/Sidenav';
import Topbar from '../components/Topbar';
import BondOffering from './bondOffering/index';
import Comments from './Comments';
import Documents from './Documents';
import Settings from './Settings';
import { fulfillOverview } from '../reducers/offering/actions';

function Home({ dispatch, offering }) {
  useEffect(() => {
    if (!offering.overviewFetched) {
      dispatch(fulfillOverview());
    }
  }, [dispatch, offering.overviewFetched]);

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidenav />
        <div className="col no-gutters dash" style={{ minHeight: '100vh' }}>
          <Topbar />
          <div className="container-fluid dash-content-box">
            <div className="container-fluid dash-content">
              <Switch>
                <Route path="/app" exact render={() => <BondOffering {...offering} />} />
                <Route path="/app/comments" exact render={() => <Comments />} />
                <Route path="/app/documents" exact render={() => <Documents />} />
                <Route path="/app/settings" exact render={() => <Settings />} />
                <Redirect to="/app" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  offering: state.offering
});

export default connect(mapStateToProps)(Home);
