import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Box, Divider } from '@mui/material';
import * as userActions from '../../reducers/user/actions';
import Spacer from '../../components/UI/Spacer';
import stylesMain from '../../styles/smbxStyles.module.scss'; // renamed file & updated import
import useStyles from '../../web-component-library/smbx-commons/styles/UI/buttonStyle';

function SigninForm({ location, history }) {
  const dispatch = useDispatch();
  const usernameRef = useRef('');
  const passwordRef = useRef('');
  const [formReady, setFormReady] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const classes = useStyles();

  const checkFormReady = useCallback(() => {
    setFormReady(usernameRef.current.value !== '' && passwordRef.current.value !== '');
  }, []);

  const signin = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(
        userActions.signin(
          usernameRef.current.value,
          passwordRef.current.value,
          history,
          location.state
        )
      );
    },
    [dispatch, history, location.state]
  );

  const togglePassword = useCallback(() => {
    setPasswordShown((prev) => !prev);
  }, []);

  return (
    <form onSubmit={signin}>
      <Spacer vertical={20} />
      <Box className={stylesMain.main_form_title}>Sign In</Box>
      <Spacer vertical={20} />
      <Divider className={stylesMain.main_divider} />
      <Spacer vertical={20} />
      <label htmlFor="inputEmail">Username</label>
      <input
        onChange={checkFormReady}
        type="text"
        id="inputEmail"
        className={`form-control ${stylesMain.main_form_input}`}
        name="username"
        ref={usernameRef}
        required
      />
      <Spacer vertical={20} />
      <label htmlFor="inputPassword">Password</label>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <input
          onChange={checkFormReady}
          type={passwordShown ? 'text' : 'password'}
          id="inputPassword"
          className={`form-control ${stylesMain.main_form_input}`}
          name="password"
          ref={passwordRef}
          required
        />
        <FontAwesomeIcon
          icon={passwordShown ? faEyeSlash : faEye}
          className={stylesMain.main_form_passwordIcon}
          onClick={togglePassword}
          role="button"
          tabIndex={0}
        />
      </Box>
      <Link to="/signin?recover=true" className={stylesMain.main_link_primary}>
        Forgot password?
      </Link>
      <Spacer vertical={40} />
      <Button
        className={`${classes.root} ${classes.primary} ${classes.ft16}`}
        type="submit"
        disabled={!formReady}>
        Sign in
      </Button>
    </form>
  );
}

export default SigninForm;
