import React, { useState } from 'react';

function ClosablePanel({ title, children }) {
  const [visible, setVisible] = useState(true);

  function handleClose() {
    setVisible(false);
  }

  if (!visible) return null;
  return (
    <div className="smbx-content">
      <div style={{ cursor: 'pointer', float: 'right', marginTop: '-10px' }}>
        <i
          role="button"
          tabIndex={0}
          style={{ fontSize: 30 }}
          className="material-icons"
          onClick={handleClose}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleClose();
          }}>
          close
        </i>
      </div>
      <h4>{title}</h4>
      <div className="row">{children}</div>
    </div>
  );
}

export default React.memo(ClosablePanel);
