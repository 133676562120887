import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR
} from 'react-redux-notify';
import Api from '../../util/Api';
import { showLoading, hideLoading, showAsyncLoading, hideAsyncLoading } from '../ux/actions';
import * as types from './actionTypes';

const showLoadingSelector = (data, dispatch) => {
  if (Array.isArray(data) && data.length > 0) {
    dispatch(showAsyncLoading());
  } else {
    dispatch(showLoading());
  }
};

const hideLoadingSelector = (data, dispatch) => {
  if (Array.isArray(data) && data.length > 0) {
    dispatch(hideAsyncLoading());
  } else {
    dispatch(hideLoading());
  }
};

export const deleteComment = (commentID) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await Api.getInstance().deleteComment(commentID);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Comment Deleted',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  } catch (error) {
    dispatch(hideLoading());
    dispatch(
      createNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: error.message,
        duration: 2000
      })
    );
  }
};

export const deleteReply = (replyID, commentID) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await Api.getInstance().deleteReply(replyID, commentID);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Comment Deleted',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  } catch (error) {
    dispatch(hideLoading());
    dispatch(
      createNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: error.message,
        duration: 2000
      })
    );
  }
};

export const fullfillComments = () => async (dispatch, getState) => {
  showLoadingSelector(getState().comments, dispatch);
  try {
    const response = await Api.getInstance().getComments();
    hideLoadingSelector(getState().comments, dispatch);
    if (response.success) {
      dispatch({ type: types.FULFILL_COMMENTS, payload: response.data });
    }
  } catch (error) {
    hideLoadingSelector(getState().comments, dispatch);
    // Optionally, dispatch an error notification
  }
};

export const postComment = (comment) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await Api.getInstance().postComment(comment);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Comment Posted',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  } catch (error) {
    dispatch(hideLoading());
    dispatch(
      createNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: error.message,
        duration: 2000
      })
    );
  }
};

export const postReply = (text, commentId) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await Api.getInstance().postSubcomment(text, commentId);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Reply posted',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  } catch (error) {
    dispatch(hideLoading());
    dispatch(
      createNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: error.message,
        duration: 2000
      })
    );
  }
};

export const updateComment = (commentID, comment) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await Api.getInstance().updateComment(commentID, comment);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch({ type: types.UPDATE_COMMENT, payload: response.data });
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Comment Updated',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  } catch (error) {
    dispatch(hideLoading());
    dispatch(
      createNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: error.message,
        duration: 2000
      })
    );
  }
};

export const updateReply = (commentId, replyId, text) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await Api.getInstance().updateReply({ commentId, replyId, text });
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch({ type: types.UPDATE_COMMENT, payload: response.data });
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Reply Updated',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  } catch (error) {
    dispatch(hideLoading());
    dispatch(
      createNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: error.message,
        duration: 2000
      })
    );
  }
};
