import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideConfirm } from '../reducers/ux/actions';
import stylesButton from '../styles/smbxStyles.module.scss'; // renamed file & updated import

function Confirm() {
  const dispatch = useDispatch();
  const { title, body, onConfirm, visible } = useSelector((state) => state.ux.confirm);

  function handleClose() {
    dispatch(hideConfirm());
  }

  function handleContinue() {
    if (onConfirm) {
      onConfirm();
    }
    dispatch(hideConfirm());
  }

  if (!visible) return null;
  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100vh',
        zIndex: 3,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.3)'
      }}>
      <div
        style={{
          width: 500,
          height: 500,
          backgroundColor: '#fff',
          padding: 50,
          paddingTop: 30,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <h4>{title}</h4>
          <div
            style={{
              position: 'absolute',
              top: 30,
              right: 20,
              cursor: 'pointer'
            }}>
            <button type="button" onClick={handleClose}>
              <i style={{ fontSize: 30 }} className="material-icons">
                close
              </i>
            </button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
          }}>
          <p style={{ fontSize: 20 }}>{body}</p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <button
            type="button"
            style={{ margin: 0, marginRight: 50 }}
            className={stylesButton.btn__secondary}
            onClick={handleClose}>
            Cancel
          </button>
          <button type="button" className={stylesButton.btn__primary} onClick={handleContinue}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Confirm);
