import React, { useState, useCallback } from 'react';
import ReactQuill from 'react-quill';
import { Button } from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';
import useStyles from '../web-component-library/smbx-commons/styles/UI/buttonStyle';

function ReplyForm({ editValue = '', onSubmitForm, buttonName }) {
  const [commentValue, setCommentValue] = useState(editValue);
  const classes = useStyles();

  const handlePostReply = useCallback(
    (e) => {
      e.preventDefault();
      onSubmitForm(commentValue);
      setCommentValue('');
    },
    [commentValue, onSubmitForm]
  );

  const disabled = commentValue.trim() === '' || commentValue === '<p><br></p>';

  return (
    <form onSubmit={handlePostReply}>
      <div className="row">
        <div className="col">
          <ReactQuill
            value={commentValue}
            onChange={setCommentValue}
            modules={{
              toolbar: [
                'bold',
                'italic',
                'underline',
                'link',
                { list: 'ordered' },
                { list: 'bullet' }
              ]
            }}
            style={{ height: '200px', marginBottom: '50px' }}
          />
          <div style={{ display: 'flex', width: '100%' }}>
            <Button
              type="submit"
              className={`${classes.root} ${classes.primary} ${classes.ft16}`}
              disabled={disabled}>
              {buttonName}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default React.memo(ReplyForm);
