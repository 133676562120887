/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useEffectOnce } from 'react-use';
import moment from 'moment-timezone';
import colors from '../../util/colors';
import { wholeNumberWithCommas } from '../../util/functions';
import Config from '../../util/Config';
import Progressbar from '../../components/Progressbar';
import BEPagination from '../../components/BEpagination';
import { fulfillPlacedBids } from '../../reducers/offering/actions';

function Close(props) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);

  // Run once on mount.
  useEffectOnce(() => {
    dispatch(fulfillPlacedBids(0));
  });

  const renderBid = (item, index) => (
    <tr key={`Placed_bids_${index}`}>
      <td>
        <p>{moment(item.created_at, Config.DATE_FORMAT).fromNow()}</p>
      </td>
      <td>{item.user_fullname}</td>
      <td className="text-right">{wholeNumberWithCommas(item.number)}</td>
      <td className="text-right">${wholeNumberWithCommas(item.number * item.principal_amount)}</td>
    </tr>
  );

  const onChangePage = (data, pageChanged) => {
    const newPage = pageChanged - 1;
    if (newPage !== currentPage) {
      dispatch(fulfillPlacedBids(newPage));
      setCurrentPage(newPage);
    }
  };

  const {
    auction_end_date_time,
    yield: bondYield,
    payments,
    maximumAmountBeingRaised,
    placedBids,
    total_sold,
    status_label,
    percent_subscribed
  } = props;

  return (
    <div>
      <div className="stats">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ ...styles.status_label, ...styles.auction }}>{status_label}</div>
          <span style={{ ...styles.time_label, marginLeft: 25 }}>
            Offering Closed on {moment(auction_end_date_time, Config.DATE_FORMAT).format('LLL')}{' '}
            Pacific
          </span>
        </div>
        <div className="card-data-box">
          <div className="row">
            <div className="col">
              <div className="card text-white card-data-offering" style={{ borderRightWidth: 0 }}>
                <div className="card-header">Target Offering</div>
                <h2 className="card-title">${wholeNumberWithCommas(maximumAmountBeingRaised)}</h2>
              </div>
            </div>
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Interest Rate</div>
                <h3 className="card-title">{bondYield}%</h3>
              </div>
            </div>
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Term</div>
                <h3 className="card-title">{payments} months</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="card-data-box">
          <div className="row">
            <div className="col">
              <div className="card text-white card-data-offering" style={{ borderRightWidth: 0 }}>
                <div className="card-header">Amount Raised</div>
                <h3 className="card-title">${wholeNumberWithCommas(total_sold * 10)}</h3>
              </div>
            </div>
            <div className="col">
              <div
                className="card text-white card-data-offering"
                style={{ borderRightWidth: 0, paddingTop: 25 }}>
                <Progressbar limit={maximumAmountBeingRaised / 10} reached={total_sold} />
              </div>
            </div>
            <div className="col">
              <div
                className="card text-white card-data-offering"
                style={{ borderRightWidth: 0, paddingTop: 50 }}>
                <p style={{ color: '#000' }}>
                  {wholeNumberWithCommas(percent_subscribed)}% subscribed
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="smbx-content">
        <h4>The bond offering has closed.</h4>
        <h4>Your SMBX advisor is reviewing the outcome and will be in touch shortly.</h4>
        <h5>If you have any questions, contact your SMBX advisor</h5>
      </div>

      <div className="smbx-content">
        <div className="row">
          <div className="col">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Investor</th>
                  <th className="text-right">Bonds</th>
                  <th className="text-right">Amount</th>
                </tr>
              </thead>
              {placedBids ? <tbody>{placedBids.map(renderBid)}</tbody> : null}
            </table>
            {placedBids && (
              <BEPagination
                currentPage={currentPage + 1}
                totalRecords={placedBids.elements}
                seePagination
                pageSize={100}
                items={placedBids}
                onChangePage={onChangePage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  status_label: {
    padding: '15px 25px',
    fontFamily: 'Lato',
    color: '#fff',
    fontSize: 15,
    maxWidth: 300,
    width: '33.334%',
    height: 50,
    fontStyle: 'normal'
  },
  auction: {
    backgroundColor: colors.CLOSE
  },
  time_label: {
    fontSize: 15,
    fontFamily: 'Lato',
    fontWeight: 400
  }
};

const mapStateToProps = (state) => {
  const { offering } = state;
  return {
    status: offering.status,
    status_label: offering.status_label,
    auction_end_date_time: offering.auction_end_date_time,
    yield: offering.yield,
    payments: offering.payments,
    maximumAmountBeingRaised: offering.maximumAmountBeingRaised,
    placedBids: offering.placedBids,
    total_sold: offering.total_sold || 0,
    percent_subscribed: offering.percent_subscribed
  };
};

export default connect(mapStateToProps)(Close);
