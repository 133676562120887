/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import colors from '../../util/colors';
import { numberWithCommas } from '../../util/functions';
import Config from '../../util/Config';
import iosIcon from '../../assets/img/apple-app-store-icon.png';

function RoadShow(props) {
  const { revenue, auction_start_date_time, yield: bondYield, payments } = props;
  return (
    <div>
      <div className="stats">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              padding: '15px 25px',
              backgroundColor: colors.ROAD_SHOW,
              color: '#fff',
              fontSize: 15
            }}>
            Road Show
          </div>
          <span style={{ fontSize: 15, fontFamily: 'Lato', fontWeight: 400, marginLeft: 25 }}>
            Auction Starts {moment(auction_start_date_time, Config.DATE_FORMAT).format('LLL')}
          </span>
          <div
            style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <span style={{ fontSize: 15, fontWeight: 300 }}>
              {moment(auction_start_date_time, Config.DATE_FORMAT).fromNow()}
            </span>
          </div>
        </div>
        <div className="card-data-box">
          <div className="row">
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Target Offering</div>
                <h2 className="card-title">${numberWithCommas(revenue)}</h2>
              </div>
            </div>
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Interest Rate</div>
                <h3 className="card-title">{bondYield}%</h3>
              </div>
            </div>
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Term</div>
                <h3 className="card-title">{payments} months</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="smbx-content">
          <h4>Your bond offering will start soon.</h4>
          <br />
          <div className="row">
            <div className="col-6" style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <p style={{ fontSize: 15, fontWeight: '400' }}>
                  To view your bond offering, download the SMBX app.
                </p>
                <img src={iosIcon} width="120px" alt="Download the SMBX app" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { offering } = state;
  return {
    raise: offering.raise,
    yield: offering.yield,
    revenue: offering.maximumAmountBeingRaised,
    total_bids: offering.total_bids,
    payments: offering.payments,
    auction_start_date_time: offering.auction_start_date_time
  };
};

export default connect(mapStateToProps)(RoadShow);
